import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { List, Typography } from 'antd';
import { Text } from '@/common/components/Typography/Text';
import { SearchInput } from 'ui-sesame-components';

export const SearchOverlay = ({ data, onClick, searchForEntityType }: SearchOverlayProps) => {
  const [searchValue, setSearchValue] = useState<string>();

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
    },
    [setSearchValue]
  );

  const clearSearchValue = useCallback(
    (id: string) => {
      setSearchValue(undefined);
      onClick(id);
    },
    [setSearchValue, onClick]
  );

  const translator = useIntl();

  return (
    <div style={{ width: '300px' }}>
      <SearchInput
        value={searchValue}
        onChange={handleSearchChange}
        placeholder={translator.formatMessage(
          {
            id: 'generic.searchForAnEntity'
          },
          { entityType: searchForEntityType }
        )}
        style={{ marginBottom: '0.5rem' }}
        allowClear
      />
      <SearchList data={data} onClick={clearSearchValue} filterValue={searchValue} />
    </div>
  );
};

interface LoadMoreProps {
  slice: number;
  setSlice: React.Dispatch<React.SetStateAction<number>>;
  step?: number;
}

export const LoadMore: React.FunctionComponent<LoadMoreProps> = React.memo(({ slice, setSlice, step = 50 }) => {
  const onClick = useCallback(() => {
    setSlice(slice + step);
  }, [setSlice, slice, step]);

  const style = useMemo<React.CSSProperties>(() => {
    return { marginTop: 12, textAlign: 'center' };
  }, []);

  return (
    <div style={style} onClick={onClick}>
      <Text link>
        <FormattedMessage id="generic.loadMore" />
      </Text>
    </div>
  );
});

const SearchList = ({ data, filterValue, onClick }: SearchListProps) => {
  const filteredData = data.filter(item => item.label !== null).sort((a, b) => `${a.label}`.localeCompare(`${b.label}`));

  const [slice, setSlice] = useState(50);

  const slicedData = useMemo(() => {
    return filteredData.slice(0, slice);
  }, [slice, filteredData]);

  const sortedData = useMemo(() => {
    if (filterValue) {
      return filteredData.filter(item => item.label.toLowerCase().trim().includes(filterValue.toLowerCase().trim())).sort((a, b) => `${a.label}`.localeCompare(`${b.label}`));
    }

    return slicedData;
  }, [filteredData, filterValue, slicedData]);

  const showLoadMore = !filterValue && slice < filteredData.length;

  return (
    <List
      itemLayout="horizontal"
      dataSource={sortedData}
      loadMore={showLoadMore ? <LoadMore slice={slice} setSlice={setSlice} /> : null}
      renderItem={item => (
        <List.Item style={{ paddingBottom: '0.3rem', paddingTop: '0.3rem' }}>
          <Typography.Link ellipsis onClick={() => onClick(item.id)} style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
            {item.label}
          </Typography.Link>
        </List.Item>
      )}
      style={{
        height: '13rem',
        width: '100%',
        overflowY: 'scroll',
        ...(!showLoadMore && { paddingBottom: '1rem' })
      }}
    />
  );
};

interface SearchOverlayProps {
  data: ListItem[];
  onClick: (id: string) => void;
  searchForEntityType: string;
}

interface SearchListProps {
  data: ListItem[];
  onClick: (id: string) => void;
  filterValue?: string;
}

export type ListItem = {
  id: string;
  label: string;
};
