import { Document } from '../../types/Document';
import { SearchCriteria } from '../../types/SearchCriteria';
import searchDocuments, { DocumentLookupResponse } from '../../api/searchDocuments';
import { DocumentTagType, SystemTag, TagValueType } from '../../../../common/types/entity/DocumentTags';
import getDocument from '../../api/getDocument';
import validateGuid4 from '@/utils/validateGuid4';
import DocumentTag from '../../types/DocumentTag';
import { DocumentLookup } from '@/common/types/entity/DocumentLookup';
import getMetadataValues from '@/api/getMetadataValues';
import { MetadataEntityType, MetadataValueDto } from '@/common/types/entity/Metadata';
import DocumentTagValue from '../../types/DocumentTagValue';
import { getLinkForEntities } from '../../api/getLinkForEntities';
import { EntityLinkType, LinkLookup } from '@/common/types/entity/Link';
import { HoldingSetLookup } from '@/common/types/entity/HoldingSetLookup';
import { TablePaginationConfig } from 'antd';
import { RecordType } from '@datadog/browser-rum/internal';
import { SorterResult } from 'antd/lib/table/interface';
import { parseCustomTags } from '@/modules/DMS/recoil/dms.selectors';
import { UUID } from '@/common/types/types';

export function queryBySlice<D extends unknown[], R>(dataSource: D, query: (slicedList: typeof dataSource) => R, slice = 50) {
  let start = 0;
  let end = slice;

  const queries = [];

  while (start < dataSource.length) {
    queries.push(query(dataSource.slice(start, end) as D));

    start = end;
    end += slice;
  }

  return queries;
}

interface DocumentsWithPages {
  documents: Document<TagValueType>[];
  documentLinksPerDocId: Record<string, LinkLookup[]>;
  currentPage: number;
  totalPages: number;
  pageSize: number;
}

export interface DMSFilters {
  tagsFilter?: string[];
  entitiesFilter?: string[];
  assetFilters?: string[];
  sharedFilter?: boolean;
  dateFilter?: [string, string];
}

export default async (
  accountId: UUID,
  userId: UUID,
  searchCriteria: SearchCriteria,
  hsetMap: Map<number, HoldingSetLookup>,
  assets: Array<any>,
  pagination: TablePaginationConfig,
  sorter: SorterResult<RecordType>,
  allDocumentTags: DocumentTag[],
  filters: DMSFilters
): Promise<DocumentsWithPages> => {
  const customTags = new Map(parseCustomTags(allDocumentTags).map(tag => [tag.id, tag]));

  const { documentsFromApi, currentPage, totalPages, pageSize } = await resolveDocumentGetter(accountId, userId, searchCriteria, pagination, sorter, customTags, filters);

  if (documentsFromApi.length === 0) {
    return Promise.resolve({ documents: [], totalPages });
  }
  const retrieveDocumentsFromMetadata = async (documents: DocumentLookup[]) => {
    return await getMetadataValues(
      MetadataEntityType.DOCUMENT,
      documents.map(doc => doc.id),
      allDocumentTags ? allDocumentTags.filter(tag => ![DocumentTagType.ASSET, DocumentTagType.HOLDING_SET].includes(tag.type)).map(tag => tag.id) : []
    );
  };

  const documentsQueriesResults = await Promise.all(
    queryBySlice<typeof documentsFromApi, ReturnType<typeof retrieveDocumentsFromMetadata>>(documentsFromApi, retrieveDocumentsFromMetadata)
  );

  const metadataValuesPerDocId = documentsQueriesResults.reduce((docObject, value) => {
    return Object.assign(docObject, value);
  }, {});

  const retrieveEntityLinks = async (documents: DocumentLookup[]) => {
    return await getLinkForEntities(
      EntityLinkType.DOCUMENT,
      documents.map(doc => doc.id)
    );
  };

  const linksQueriesResults = await Promise.all(queryBySlice<typeof documentsFromApi, ReturnType<typeof retrieveEntityLinks>>(documentsFromApi, retrieveEntityLinks));

  const documentLinksPerDocId = linksQueriesResults.reduce((linkObject, value) => {
    return Object.assign(linkObject, value);
  }, {});

  return {
    documents: documentsFromApi.map(document => {
      const tags = tagsFromMetadataValues(document, allDocumentTags, metadataValuesPerDocId);

      enrichTagsFromEntities(document, documentLinksPerDocId, tags, hsetMap, assets, searchCriteria.filter?.value?.value?.id, filters);

      return Document.fromLookup(document, tags);
    }),
    documentLinksPerDocId,
    totalPages,
    currentPage,
    pageSize
  };
};

const resolveDocumentGetter = async (
  accountId: UUID,
  userId: UUID,
  searchCriteria: SearchCriteria,
  pagination: TablePaginationConfig,
  sorter: SorterResult<RecordType>,
  customTags: Map<string, DocumentTag>,
  filters: DMSFilters
): Promise<DocumentLookupResponse> => {
  if (validateGuid4(searchCriteria.query)) {
    const doc = await getDocument(searchCriteria.query);
    const documentsFromApi = [];

    if (doc) {
      documentsFromApi.push(doc);
    }

    return { documentsFromApi, currentPage: 0, totalPages: 1, pageSize: 10 };
  } else {
    return searchDocuments(accountId, userId, searchCriteria, pagination, sorter, customTags, filters);
  }
};

const tagsFromMetadataValues = (
  document: DocumentLookup,
  allDocumentTags: DocumentTag[],
  metadataValuesPerDocId: Record<string, MetadataValueDto[]>
): Record<string, DocumentTagValue<TagValueType>> => {
  if (Object.keys(metadataValuesPerDocId).length === 0) {
    return {};
  }
  const metadataValues = metadataValuesPerDocId[document.id];
  if (metadataValues === undefined) {
    return {};
  }

  return allDocumentTags.reduce((tags, tag) => {
    const correspondingValues = metadataValues.filter(it => it.keyId === tag.id);

    correspondingValues.forEach(correspondingValue => {
      tags[tag.name] = DocumentTagValue.from(tag, correspondingValue.value);
    });

    return tags;
  }, {} as Record<string, DocumentTagValue<TagValueType>>);
};

const enrichTagsFromEntities = (
  document: DocumentLookup,
  documentLinksPerDocId: Record<string, LinkLookup[]>,
  existingTags: Record<string, DocumentTagValue<TagValueType>>,
  hsetMap: Map<number, HoldingSetLookup>,
  assets: Array<any>,
  tagValueEntityId?: number,
  filters?: DMSFilters
) => {
  documentLinksPerDocId[document.id]?.forEach(link => {
    if (link.type === EntityLinkType.ASSET) {
      const fromId = parseInt(link.entityId);

      if (tagValueEntityId && fromId !== tagValueEntityId) {
        return;
      }

      if (filters?.assetFilters?.length) {
        const ids = filters?.assetFilters.map(Number);

        if (!ids.includes(fromId)) {
          return;
        }
      }

      const asset = assets.find(asset => asset.id === link.entityId);

      existingTags[SystemTag.Asset] = DocumentTagValue.fromValue<TagValueType>(
        DocumentTag.fromLink(link),
        fromId as any,
        asset ? { id: fromId, name: asset.label } : ({ id: fromId } as any)
      );
    } else if ([EntityLinkType.HOLDING_SET].includes(link.type)) {
      const fromId = parseInt(link.entityId);

      if (tagValueEntityId && fromId !== tagValueEntityId) {
        return;
      }

      if (filters?.entitiesFilter?.length) {
        const ids = filters?.entitiesFilter.map(Number);

        if (!ids.includes(fromId)) {
          return;
        }
      }

      const hset = hsetMap.get(fromId);

      existingTags[SystemTag.Entity] = DocumentTagValue.fromValue<TagValueType>(
        DocumentTag.fromLink(link, DocumentTagType.HOLDING_SET),
        fromId as any,
        hset ? { id: hset.id, name: hset.name } : ({ id: fromId } as any)
      );
    }
  });
};
