import { atom, atomFamily, selectorFamily } from 'recoil';
import { EntityLinkType, LinkLookup } from '@/common/types/entity/Link';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { getLinks } from '@/modules/ERP/Contacts/Api/getLinks';
import { MetadataObjectSelectorParameters } from '@/modules/ERP/Common/types';
import { getObjectMetadata, MetadataObject } from '@/modules/ERP/Common/Api/getObjectMetadata';
import { getMetadataFile, MetadataFile } from '@/modules/ERP/Common/Api/getMetadataFile';
import { UUID } from '@/common/types/types';
import { MetadataEntityType } from '@/common/types/entity/Metadata';
import { EntityType } from '@/modules/Onboarding/Shared/types';
import { legalEntityByHoldingSetId } from '../../PortfolioV2/recoil/legalEntities';
import getContactPhotoUrl from "@/modules/Onboarding/api/upload/getContactPhotoUrl";

const entityLinksSelector = selectorFamily<LinkLookup[], [EntityLinkType, Primitive]>({
  key: 'entity-links-selector',
  get:
    ([linkType, id]) =>
    async ({ get }) => {
      get(linksLastUpdated);
      const links = await getLinks(linkType, id);
      const newLinks: LinkLookup[] = [];
      links.forEach(link => {
        if (link.type === EntityLinkType.HOLDING_SET) {
          const currentEntityType = get(legalEntityByHoldingSetId({ holdingSetId: Number(link.entityId) })).holdingSetType;
          if (currentEntityType === EntityType.PORTFOLIO || currentEntityType === EntityType.GROUPING) {
            link.type = currentEntityType as unknown as EntityLinkType;
          } else {
            link.type = EntityType.VEHICLE as unknown as EntityLinkType;
          }
        }
        newLinks.push(link);
      });
      return newLinks.filter((item, index) => {
        return newLinks.findIndex(i => i.entityId === item.entityId) === index;
      });
    }
});

export enum ERPFilterStateDefaultValue {
  DMS_TAGS_DEFAULT_VALUE = 'All',
  DMS_ENTITY_DEFAULT_VALUE = 'All',
  DMS_ASSET_DEFAULT_VALUE = 'All'
}

const erpFilterState = atomFamily<string | string[] | undefined, string>({
  key: 'erp-activity-filters-state',
  default: undefined
});

export const metadataObjectSelector = selectorFamily<string | undefined, MetadataObjectSelectorParameters>({
  key: 'erp-metadata-object-selector',
  get:
    ({ keyId, entityId, entityType }) =>
    async ({ get }) => {
      try {
        const metadata = get(objectMetadataSelector({ type: entityType, id: entityId }));
        const fileId = metadata.find(item => item.keyId === keyId)?.value;

        if (fileId) {
          const file = get(fileFromMetadataSelector({ entityType, fileId }));

          return file.uri + '?' + file.signature;
        }
      } catch (err) {
        return undefined;
      }
    }
});

const objectMetadataSelector = selectorFamily<Array<MetadataObject>, { type: MetadataEntityType; id: Primitive }>({
  key: 'object-metadata-selector',
  get:
    ({ type, id }) =>
    () => {
      return getObjectMetadata(type, id);
    }
});

const fileFromMetadataSelector = selectorFamily<MetadataFile, { entityType: MetadataEntityType; fileId: UUID }>({
  key: 'erp-file-from-metadata-selector',
  get:
    ({ entityType, fileId }) =>
    () => {
      return getMetadataFile(entityType, fileId);
    }
});

export const linksLastUpdated = atom({
  key: 'erp-links-last-updated',
  default: new Date()
});

export const contactPhotoSelectorUrl = selectorFamily<string, string>({
  key: 'contact-photo-selector-link',
  get: contactId => async () => {
    return getContactPhotoUrl(contactId);
  }
});

export { erpFilterState, entityLinksSelector };
