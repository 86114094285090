import { atom, atomFamily } from 'recoil';
import { PageTemplate } from '@/utils/retrievePageTemplate';
import { Contact } from '../types';

const contactFilterState = atomFamily<string, PageTemplate>({
  key: 'contacts-filter-state',
  default: undefined
});

const contactsCompanyFilterState = atomFamily<string | undefined, PageTemplate>({
  key: 'contacts-company-filter-state',
  default: undefined
});

const contactsCountryFilterState = atomFamily<string | undefined, PageTemplate>({
  key: 'contacts-country-filter-state',
  default: undefined
});

const manageContactPropertiesTableState = atom<boolean>({
  key: 'maange-contact-properties-table-state',
  default: false
});
export const erpSelectedContactsAtom = atom<Contact[]>({
  key: 'erp-selected-contact-atom',
  default: []
});

const contactsDataSheetActive = atom<boolean>({
  key: 'contacts-datasheet-active',
  default: false
});

export { contactFilterState, contactsCompanyFilterState, contactsCountryFilterState, contactsDataSheetActive, manageContactPropertiesTableState };
