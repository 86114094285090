import TransientDocumentTag from '@/modules/DMS/types/TransientDocumentTag';
import HierarchicalEntityLookup from '@/common/types/entity/HierarchicalEntityLookup';
import { BasicTagType, EntityTagType } from '@/common/types/entity/DocumentTags';
import TransientDocumentTagValue from '@/modules/DMS/types/TransientDocumentTagValue';
import { DocumentTagValueNode } from '@/modules/DMS/components/EntityTree/TagTree';
import EntityLookup from '@/common/types/entity/EntityLookup';

export const convertToDocumentTag = (
  tag: TransientDocumentTag,
  lookup: HierarchicalEntityLookup<EntityTagType>,
  tagValues: Map<number, TransientDocumentTagValue<EntityTagType>>
): DocumentTagValueNode<EntityTagType> => {
  return {
    value: tagValues.get(lookup.id) || new TransientDocumentTagValue(tag, tag.type, lookup),
    children: lookup.children?.map(node => convertToDocumentTag(tag, node, tagValues))
  } as DocumentTagValueNode<EntityTagType>;
};

export const buildEntityTree = async (
  entities: HierarchicalEntityLookup<EntityLookup>[],
  tag: TransientDocumentTag,
  tagValues: TransientDocumentTagValue<EntityTagType>[]
): Promise<DocumentTagValueNode<EntityTagType>[]> => {
  const tagValuesMap = new Map(tagValues.map(value => [value.value.id, value]));
  return entities.map(node => convertToDocumentTag(tag, node, tagValuesMap));
};

export const buildBasicTree = async (tag: TransientDocumentTag, tagValues: TransientDocumentTagValue<BasicTagType>[]): Promise<DocumentTagValueNode<BasicTagType>[]> => {
  return Promise.resolve(
    tagValues.map(value => {
      return { value } as DocumentTagValueNode<BasicTagType>;
    })
  );
};
export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
