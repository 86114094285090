import { requestApi } from '@/core';
import { SearchCriteria } from '../types/SearchCriteria';
import { DocumentLookup } from '../../../common/types/entity/DocumentLookup';
import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { RecordType } from '@datadog/browser-rum/internal';
import { Direction } from '@/utils/Sort';
import { DocumentTagType } from '@/common/types/entity/DocumentTags';
import { EntityLinkType } from '@/common/types/entity/Link';
import DocumentTag from '@/modules/DMS/types/DocumentTag';
import { DMSFilters } from '@/modules/DMS/components/DocumentsTable/getDataSource';
import { ERPFilterStateDefaultValue } from '@/modules/ERP/Common/Recoil/erp.selectors';
import dayjs from 'dayjs';
import Params from '@/modules/DMS/types/Params';
import { ViewTypeConfigTitle } from '@/modules/DMS/types/ViewType';
import { getRecentEntities, RecentEntityModule } from '@/modules/GlobalSearch/api/getRecentEntities';
import { UUID } from '@/common/types/types';

export interface DocumentLookupResponse {
  documentsFromApi: DocumentLookup[];
  totalPages: number;
  currentPage: number;
  pageSize: number;
}

const defaultSorter = 'lastModifiedDate,desc';
const convertForApi = (order: Direction) => {
  if (order === Direction.ASC) {
    return 'asc';
  }
  if (order === Direction.DESC) {
    return 'desc';
  }
};

export default async (
  accountId: UUID,
  userId: UUID,
  searchCriteria: SearchCriteria,
  pagination: TablePaginationConfig,
  sorter?: SorterResult<RecordType>,
  customTags = new Map<string, DocumentTag>(),
  filters: DMSFilters = {}
): Promise<DocumentLookupResponse> => {
  const isRecentDocuments = new URLSearchParams(window.location.search).get(Params.view) === ViewTypeConfigTitle.RECENT_DOCUMENTS;

  const recentDocumentIds = [];

  if (isRecentDocuments) {
    const recentDocs = await getRecentEntities(accountId, userId, { module: RecentEntityModule.DOCUMENT_VIEWED });

    recentDocumentIds.push(...recentDocs.map(doc => doc.entityId));

    if (!recentDocumentIds.length) {
      return {
        pageSize: 0,
        documentsFromApi: [],
        totalPages: 0
      };
    }
  }

  const sort = sorter?.field && sorter?.order ? `${sorter.field},${convertForApi(sorter.order as Direction)}` : defaultSorter;

  const entityLinkedTypesIds = {} as any;
  const metadataKeysValues = {} as any;

  const searchCriteriaFilter = searchCriteria.filter;
  const searchCriteriaFilterTagId = searchCriteriaFilter?.tag?.id;
  const searchCriteriaFilterTagType = searchCriteriaFilter?.tag?.type;

  const unclassifiedParams: {
    withoutLink?: EntityLinkType;
    withoutMetadata?: string;
  } = {};
  if (searchCriteriaFilter?.inverse === true) {
    if (searchCriteriaFilterTagType === DocumentTagType.HOLDING_SET) {
      unclassifiedParams.withoutLink = EntityLinkType.HOLDING_SET;
    } else if (searchCriteriaFilterTagType === DocumentTagType.ASSET) {
      unclassifiedParams.withoutLink = EntityLinkType.ASSET;
    } else {
      unclassifiedParams.withoutMetadata = searchCriteriaFilterTagId!;
    }
  } else {
    const customTag = customTags.get(searchCriteriaFilterTagId!);

    if (customTag) {
      metadataKeysValues[searchCriteriaFilterTagId!] = [];

      const searchCriteriaFilterTagValue = searchCriteriaFilter?.value?.value;

      if (searchCriteriaFilterTagValue && customTag.allowedValues?.includes(searchCriteriaFilterTagValue)) {
        metadataKeysValues[searchCriteriaFilterTagId!].push(searchCriteriaFilterTagValue);
      }
    } else {
      const searchCriteriaFilterValueId = searchCriteriaFilter?.value?.value?.id;

      if (searchCriteriaFilterTagType === DocumentTagType.HOLDING_SET) {
        entityLinkedTypesIds[EntityLinkType.HOLDING_SET] = [];

        if (searchCriteriaFilterValueId) {
          entityLinkedTypesIds[EntityLinkType.HOLDING_SET].push(searchCriteriaFilterValueId);
        }
      } else if (searchCriteriaFilterTagType === DocumentTagType.ASSET) {
        entityLinkedTypesIds[EntityLinkType.ASSET] = [];

        if (searchCriteriaFilterValueId) {
          entityLinkedTypesIds[EntityLinkType.ASSET].push(searchCriteriaFilterValueId);
        }
      }
    }
  }

  if (filters.tagsFilter?.length) {
    const values = filters.tagsFilter.filter(val => val !== ERPFilterStateDefaultValue.DMS_TAGS_DEFAULT_VALUE);

    if (values.length) {
      const tagsList = Array.from(customTags.values());

      values.forEach(val => {
        const tag = tagsList.find(currentTag => currentTag.allowedValues.includes(val));

        if (tag) {
          metadataKeysValues[tag.id] = metadataKeysValues[tag.id] ? [...metadataKeysValues[tag.id], val] : [val];
        }
      });
    }
  }

  if (filters.entitiesFilter?.length) {
    const values = filters.entitiesFilter.filter(val => val !== ERPFilterStateDefaultValue.DMS_ENTITY_DEFAULT_VALUE).map(Number);

    if (entityLinkedTypesIds[EntityLinkType.HOLDING_SET]) {
      entityLinkedTypesIds[EntityLinkType.HOLDING_SET] = Array.from(new Set([...entityLinkedTypesIds[EntityLinkType.HOLDING_SET], ...values]));
    } else {
      entityLinkedTypesIds[EntityLinkType.HOLDING_SET] = values;
    }
  }

  if (filters.assetFilters?.length) {
    const values = filters.assetFilters.filter(val => val !== ERPFilterStateDefaultValue.DMS_ASSET_DEFAULT_VALUE).map(Number);

    if (entityLinkedTypesIds[EntityLinkType.ASSET]) {
      entityLinkedTypesIds[EntityLinkType.ASSET] = Array.from(new Set([...entityLinkedTypesIds[EntityLinkType.ASSET], ...values]));
    } else {
      entityLinkedTypesIds[EntityLinkType.ASSET] = values;
    }
  }

  const params: Record<string, any> = {
    sort
  };

  if (!isRecentDocuments) {
    params.page = pagination.current! - 1;
    params.size = pagination.pageSize;
  }

  const data: Record<string, any> = {
    name: searchCriteria.query,
    status: searchCriteria.status,
    ...unclassifiedParams,
    entityLinkedTypesIds,
    metadataKeysValues
  };

  if (filters.sharedFilter) {
    data.isShared = filters.sharedFilter;
  }

  if (isRecentDocuments) {
    data.ids = recentDocumentIds;
  }

  if (Array.isArray(filters.dateFilter)) {
    const [startDate, endDate] = filters.dateFilter;

    if (startDate) {
      data.dateMin = dayjs(startDate).unix();
    }

    if (endDate) {
      data.dateMax = dayjs(endDate).unix();
    }
  }

  const response = await requestApi({
    method: 'POST',
    url: `/api/document/search/enriched`,
    service: 'DOCUMENT',
    params,
    data
  });
  return {
    pageSize: response?.data.pageable.pageSize,
    currentPage: response?.data.pageable.pageNumber,
    documentsFromApi: response?.data.content,
    totalPages: response?.data.totalElements
  };
};
